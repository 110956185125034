// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoFindModal_modal__WTmf4 {
  align-items: flex-end;
  padding: 12px;
}
@media (min-width: 640px) {
  .NoFindModal_modal__WTmf4 {
    align-items: center;
    padding: 66px 40px 40px;
  }
}
.NoFindModal_modal__WTmf4 .modal__inner {
  max-width: 560px;
}

.NoFindModal_footer__We7du {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  grid-gap: 12px;
}
@media (min-width: 1200px) {
  .NoFindModal_footer__We7du {
    margin-top: 24px;
  }
}

@media (min-width: 1200px) {
  .NoFindModal_btnSubmit__9MmHV {
    max-width: 204px;
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/NoFindModal/NoFindModal.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,aAAA;AACF;AACE;EAJF;IAKI,mBAAA;IACA,uBAAA;EAEF;AACF;AAEM;EACE,gBAAA;AAAR;;AAMA;EACE,gBAAA;EACA,aAAA;EACA,yBAAA;EACA,cAAA;AAHF;AAKE;EANF;IAOI,gBAAA;EAFF;AACF;;AAME;EADF;IAEI,gBAAA;IACA,WAAA;EAFF;AACF","sourcesContent":[".modal {\r\n  align-items: flex-end;\r\n  padding: 12px;\r\n\r\n  @media (min-width: 640px) {\r\n    align-items: center;\r\n    padding: 66px 40px 40px;\r\n  }\r\n\r\n  :global {\r\n    .modal {\r\n      &__inner {\r\n        max-width: 560px;\r\n      }\r\n    }\r\n  }\r\n}\r\n\r\n.footer {\r\n  margin-top: 32px;\r\n  display: flex;\r\n  justify-content: flex-end;\r\n  grid-gap: 12px;\r\n\r\n  @media (min-width: 1200px) {\r\n    margin-top: 24px;\r\n  }\r\n}\r\n\r\n.btnSubmit {\r\n  @media (min-width: 1200px) {\r\n    max-width: 204px;\r\n    width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `NoFindModal_modal__WTmf4`,
	"footer": `NoFindModal_footer__We7du`,
	"btnSubmit": `NoFindModal_btnSubmit__9MmHV`
};
export default ___CSS_LOADER_EXPORT___;
