import Layout from "../components/Layout/Layout"
import MainLayout from "../components/MainLayout/MainLayout"
import MainLayoutLoader from "../components/LayoutLoaders/MainLayoutLoader"

export default function Home() {
  return (
    <Layout mainLoader={<MainLayoutLoader />}>
      <MainLayout />
    </Layout>
  )
}
