import {
  Action,
  createAsyncThunk,
  createSlice,
  isAsyncThunkAction,
  isRejectedWithValue,
  PayloadAction,
} from "@reduxjs/toolkit"
// import {HYDRATE} from 'next-redux-wrapper'
import { RootState } from "../store"
import { authApi } from "../api/auth"
import { MutationThunkArg, QueryThunkArg } from "@reduxjs/toolkit/dist/query/core/buildThunks"
// import {BaseResponseType} from '@/types/content'
// import {deleteCookie, setCookie} from 'cookies-next'
import {
  MAX_AGE_TOKEN_180_DAYS_IN_SECONDS,
  MENU_LIST_COOKIE,
  USER_OBJECT_COOKIE,
  USER_TOKEN_COOKIE,
} from "../../utils/constants"
import { deleteCookie, setCookie } from "../../utils/cookies"
import { ISettings, ISupportAuth } from "../../types/content"
import EchoConfig from "../../utils/withEcho"
// import {viewEntityInfo} from "@/types/entities";
// import {entityState, ISliceEntity} from "@/redux/slices/entity";
// import {get} from "lodash";

interface User {
  id: string
  name: string
  avatar_id: string | null
}

type AuthState = {
  user: User | null
  token: string | null
  activeOrders: number
  isAllowRegistration: boolean | null
  isAllowProposeNewServiceMain: boolean | null
  IsAllowNewServiceCategory: boolean
  support: ISupportAuth
  isLoginPending: boolean
}

const initialState: AuthState = {
  user: null,
  token: null,
  activeOrders: 0,
  isAllowRegistration: true,
  isAllowProposeNewServiceMain: null,
  IsAllowNewServiceCategory: false,
  support: {
    email: "",
    phone: "",
    site: "",
    telegram: "",
  },
  isLoginPending: true,
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: logoutFn,
    // updateUser: (state: any, action: any) => {
    //   state.user = action.payload
    // },
    setActiveOrdersCount: (state: AuthState, action: PayloadAction<number>) => {
      state.activeOrders = action.payload
    },
    addActiveOrdersCount: (state: AuthState) => {
      state.activeOrders = state.activeOrders + 1
    },
    removeActiveOrdersCount: (state: AuthState) => {
      state.activeOrders = state.activeOrders - 1
    },
    setIsAllowRegistration: (state: AuthState, action: PayloadAction<boolean>) => {
      state.isAllowRegistration = action?.payload
    },
    setIsAllowProposeNewServiceMain: (state: AuthState, action: PayloadAction<boolean>) => {
      state.isAllowProposeNewServiceMain = action?.payload
    },
    updateIsAllowNewServiceCategory: (state: AuthState, action: PayloadAction<any>) => {
      state.IsAllowNewServiceCategory = action?.payload
    },
    setAuthSupport: (state: AuthState, action: PayloadAction<ISupportAuth>) => {
      if (action?.payload) state.support = action.payload
    },
    setIsLoginPending: (state: AuthState, action: PayloadAction<boolean>) => {
      state.isLoginPending = action.payload
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(HYDRATE as string, (state, action: PayloadAction<RootState>) => {
    //   // return action.payload[authSlice.name]
    //   if (Object.keys(action.payload[authSlice.name].users).length) {
    //     state.selectlist = action.payload[authSlice.name].selectlist
    //     state.roles = action.payload[authSlice.name].roles
    //     state.users = action.payload[authSlice.name].users
    //     state.workgroups = action.payload[authSlice.name].workgroups
    //   }
    //   if (Object.keys(action.payload[authSlice.name].user).length) {
    //     state.user = action.payload[authSlice.name].user
    //   }
    // })
    // builder.addCase(getSpeclistRoles.fulfilled, (state: AuthState, action) => {
    //   const {selectlist, roles, users, workgroups} = action.payload
    //   state.selectlist = selectlist
    //   state.roles = roles
    //   state.users = users
    //   state.workgroups = workgroups
    // })
    // builder.addCase(getUsersList.fulfilled, (state, action) => {
    //   state.users = action.payload
    // })
    builder.addMatcher(authApi.endpoints.checkToken.matchPending, () => {
      authSlice.actions.setIsLoginPending(true)
    })
    builder.addMatcher(authApi.endpoints.loginSms.matchFulfilled, saveUser)
    builder.addMatcher(authApi.endpoints.checkToken.matchFulfilled, saveUser)
    builder.addMatcher(authApi.endpoints.getKerbToken.matchFulfilled, saveUser)
  },
})

function saveUser(state: AuthState, action: any) {
  state.isLoginPending = false
  const { data } = action.payload

  if (!action?.payload.errorCode && data.user) {
    const { id, name, avatar_id, accessToken, activeOrders } = data.user

    setCookie(USER_TOKEN_COOKIE, accessToken, { "max-age": MAX_AGE_TOKEN_180_DAYS_IN_SECONDS, sameSite: "lax" })

    if (typeof window.Echo === "undefined") {
      EchoConfig(accessToken)
    }

    state.user = { id, name, avatar_id }
    state.token = accessToken
    state.activeOrders = activeOrders
  } else {
    deleteCookie(USER_TOKEN_COOKIE)
  }
}

function logoutFn() {
  // if (!isRejectedWithValue(action) && isAsyncThunkAction(action)) {
  //   return state
  // }

  deleteCookie(USER_TOKEN_COOKIE, { sameSite: "lax" })
  window.Echo = undefined
  // deleteCookie(USER_OBJECT_COOKIE, { sameSite: "lax" })
  // deleteCookie(MENU_LIST_COOKIE, { sameSite: "lax" })
  return initialState
}

export const {
  logout,
  setActiveOrdersCount,
  addActiveOrdersCount,
  removeActiveOrdersCount,
  setIsAllowRegistration,
  setIsAllowProposeNewServiceMain,
  updateIsAllowNewServiceCategory,
  setAuthSupport,
  setIsLoginPending,
  // changeAuthAvatar,
  // updateUser
} = authSlice.actions
export const selectAuth = (state: any) => state[authSlice.name]
export const selectUser = (state: any) => state[authSlice.name].user
export const selectToken = (state: any) => state[authSlice.name].token
export const selectActiveOrders = (state: any) => state[authSlice.name].activeOrders
export const selectIsAllowForRegistaration = (state: any) => state[authSlice.name].isAllowRegistration
export const selectIsAllowProposeNewServiceMain = (state: any) => state[authSlice.name].isAllowProposeNewServiceMain
export const selectIsAllowNewServiceCategory = (state: any) => state[authSlice.name].IsAllowNewServiceCategory
export const selectSupportAuth = (state: any): ISupportAuth => state[authSlice.name].support
export const selectIsLoginPending = (state: any): ISupportAuth => state[authSlice.name].isLoginPending
