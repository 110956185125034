import MessagesAsideItem from "./MessagesAsideItem"
import ScrollBlock from "../../Assets/ScrollBlock"
import { IChatInfo, IChatInfoExt } from "../../../types/content"
import clsx from "clsx"
import { useInfiniteScroll } from "../../../hooks/useInfiniteScroll"
import styles from "./MessagesModal.module.scss"

const AsideListLoader = () => {
  return (
    <div>
      {[...Array(3)].map((i, index) => (
        <div key={index} className={clsx(styles.listLoader)}>
          <div className={clsx("skeletonBlock", styles.listLoader__avatar)} />
          <div>
            <div className={clsx("skeletonBlock", styles.listLoader__name)} />
            <div className={clsx("skeletonBlock", styles.listLoader__txt)} />
          </div>
        </div>
      ))}
    </div>
  )
}

const MessagesAsideList = ({
  list,
  activeChat,
  isLoading,
  callbackSearch,
  onClick,
  isAllChats,
}: {
  list: IChatInfoExt[]
  activeChat: { id: string; name: string | null | undefined; userID?: string | null }
  isLoading?: boolean
  callbackSearch?: () => void
  onClick: (val: string, name: string, userID: string | null) => void
  isAllChats: boolean
}) => {
  const infiniteScroll = useInfiniteScroll<HTMLDivElement>(
    async () => {
      if (isAllChats) return
      await new Promise((resolve) => {
        setTimeout(resolve, 1000)
        if (!callbackSearch) return
        callbackSearch()
      })
    },
    { distance: 10, isScrollPlugin: true },
  )

  return (
    <ScrollBlock
      //@ts-ignore
      ref={infiniteScroll.ref}
      hideTracksWhenNotNeeded
      fullHeightScrollBar
      scrollBarSize={4}
      className={clsx(styles.asideList, "asideList")}
    >
      {list.map((item, index) => (
        <MessagesAsideItem
          key={index}
          {...item}
          isActive={activeChat.id ? item.id === activeChat.id : item.user_id === activeChat.userID}
          onClick={onClick}
          className={styles.item}
        />
      ))}
      {infiniteScroll.isLoading && <AsideListLoader />}
      {isLoading && !list?.length && <AsideListLoader />}
    </ScrollBlock>
  )
}

export default MessagesAsideList
