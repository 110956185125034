import LocalizedLink from "../../../hoc/LocalizedLink"
import clsx from "clsx"
import { StarIcon } from "../../../icons/StarIcon"
import { useParams } from "react-router-dom"
import styles from "./ArticleItemAccordion.module.scss"

const ArticleItemAccordion = ({
  txt,
  href,
  isFavorite,
  className,
}: {
  txt: string
  href: string
  isFavorite?: boolean
  className?: string
}) => {
  const params = useParams()

  return (
    <div className={clsx("accordion__item", styles.item, params?.id === href && styles["item--is-active"], className)}>
      <LocalizedLink to={`/articles/` + href}>
        <span
          className={styles.txt}
          data-tooltip-id={"globalTooltip"}
          data-tooltip-content={txt}
          data-tooltip-delay-show={300}
        >
          {txt}
        </span>{" "}
        {isFavorite && <StarIcon />}
      </LocalizedLink>
    </div>
  )
}

export default ArticleItemAccordion
