// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArticlesAside_btn__SYUMi,
.ArticlesAside_loaderBtn__ReJmW {
  margin-bottom: 16px;
}

.ArticlesAside_btn__SYUMi {
  display: flex;
  align-items: center;
  text-align: left;
  box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.0509803922);
  background-color: var(--white);
  border-radius: 16px;
  font-size: var(--fz-lg);
  line-height: var(--lh-lg);
  font-weight: 600;
  padding: 12px 16px;
  transition: var(--transition);
}
@media (hover: hover) {
  .ArticlesAside_btn__SYUMi:is(a, button):hover {
    box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.2);
  }
}

.ArticlesAside_btnTxt__tNQ\\+0 {
  color: #7A808B;
  font-weight: normal;
  font-size: var(--fz-sm);
  line-height: var(--lh-sm);
  display: block;
}

.ArticlesAside_iconBack__ZeVnS {
  margin-right: 12px;
}

.ArticlesAside_loader__XaadZ {
  height: 120px;
  border-radius: 12px;
  margin-bottom: 24px;
}

.ArticlesAside_loaderBtn__ReJmW {
  height: 56px;
  border-radius: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/Articles/ArticlesAside/ArticlesAside.module.scss"],"names":[],"mappings":"AAAA;;EAEE,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,uDAAA;EACA,8BAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;EACA,gBAAA;EACA,kBAAA;EACA,6BAAA;AACF;AAEI;EACE;IACE,8CAAA;EAAN;AACF;;AAKA;EACE,cAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;EACA,cAAA;AAFF;;AAKA;EACE,kBAAA;AAFF;;AAKA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,YAAA;EACA,mBAAA;AAFF","sourcesContent":[".btn,\r\n.loaderBtn {\r\n  margin-bottom: 16px;\r\n}\r\n\r\n.btn {\r\n  display: flex;\r\n  align-items: center;\r\n  text-align: left;\r\n  box-shadow: 0px 5px 9px 0px #0000000D;\r\n  background-color: var(--white);\r\n  border-radius: 16px;\r\n  font-size: var(--fz-lg);\r\n  line-height: var(--lh-lg);\r\n  font-weight: 600;\r\n  padding: 12px 16px;\r\n  transition: var(--transition);\r\n\r\n  &:is(a, button) {\r\n    @media (hover: hover) {\r\n      &:hover {\r\n        box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.2);\r\n      }\r\n    }\r\n  }\r\n}\r\n\r\n.btnTxt {\r\n  color: #7A808B;\r\n  font-weight: normal;\r\n  font-size: var(--fz-sm);\r\n  line-height: var(--lh-sm);\r\n  display: block;\r\n}\r\n\r\n.iconBack {\r\n  margin-right: 12px;\r\n}\r\n\r\n.loader {\r\n  height: 120px;\r\n  border-radius: 12px;\r\n  margin-bottom: 24px;\r\n}\r\n\r\n.loaderBtn {\r\n  height: 56px;\r\n  border-radius: 16px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `ArticlesAside_btn__SYUMi`,
	"loaderBtn": `ArticlesAside_loaderBtn__ReJmW`,
	"btnTxt": `ArticlesAside_btnTxt__tNQ+0`,
	"iconBack": `ArticlesAside_iconBack__ZeVnS`,
	"loader": `ArticlesAside_loader__XaadZ`
};
export default ___CSS_LOADER_EXPORT___;
