import { useEffect, useState } from "react"
import { StarIcon } from "../../../icons/StarIcon"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import styles from "./FavoriteBtn.module.scss"

interface Props {
  isActive: boolean
  onClick: () => void
  className?: string
  isDesktop?: boolean
}

const FavoriteBtn = ({ isActive, onClick, className, isDesktop }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const [active, setActive] = useState(false)

  useEffect(() => {
    setActive(isActive)
  }, [isActive])

  return (
    <button
      type={"button"}
      className={clsx(styles.btn, active && styles["btn--is-active"], className, isDesktop && styles["desktop-type"])}
      onClick={onClick}
    >
      <StarIcon /> {!isDesktop ? t(isActive ? "inFavorites" : "favorites") : null}
    </button>
  )
}

export default FavoriteBtn
