import Layout from "../components/Layout/Layout"
import Employees from "../components/Employees/Employees"
import EmployeesLayoutLoader from "../components/LayoutLoaders/EmployeesLayoutLoader"

export default function EmployeesPage() {
  return (
    <Layout mainLoader={<EmployeesLayoutLoader />} isHeaderMobHidden>
      <Employees />
    </Layout>
  )
}
