import { FC } from "react"
import styles from "./ChatModal.module.scss"
import moment from "moment"
import { getFileIco } from "../../../utils/helpers"
import { getApiUrl } from "../../../utils/getApiUrl"
import AttachmentItemChat from "./assets/AttachmentItemChat"
import { GetUserAvatar } from "../../../utils/getUserAvatar"
import { urlify } from "../../../utils/urlify"
import { IChatMessage } from "../../../types/content"
import Avatar from "../../Assets/Avatar/Avatar"

interface Props {
  message: IChatMessage
  nowUserId: string
  openPhoto?: any // ф-я открытия фото
}

const ChatMessage: FC<Props> = ({ message, nowUserId, openPhoto = () => null }) => {
  const itsMe = message?.user?.id === nowUserId

  return (
    <div className={`${styles.chatMessage} chatMessage ${itsMe ? styles.chatMessage_my : ""} `}>
      {!itsMe && message?.user?.name ? (
        <div className={`${styles.chatMessage__avatar}`}>
          <GetUserAvatar name={message?.user?.name} avatar={message?.user?.avatar_id || false} />
          {/* <img src={`${message.user.avatar_id ? getApiUrl()+'/web/user/avatar/'+message.user.avatar_id : ''}`} alt="" /> */}
        </div>
      ) : (
        <Avatar size={"smd"} className={styles.chatMessage__avatar} />
      )}
      <div className={`${styles.chatMessage__block}`}>
        {!itsMe && message?.user?.name && <div className={`${styles.chatMessage__name}`}>{message.user.name}</div>}
        <div className={`${styles.chatMessage__text}`} dangerouslySetInnerHTML={{ __html: urlify(message.text) }} />
        {/* Изображения */}
        {!!message.photos?.length && (
          <div className={`${styles.chatMessage__images}`}>
            {message.photos?.map((el: string, kk: number) => (
              <img
                key={kk}
                onClick={() => openPhoto(message.photos_info, kk)}
                src={getApiUrl() + "/public/photo/" + el}
                alt=""
              />
            ))}
          </div>
        )}

        {/* Файлы */}
        {/* {!!message.photos?.length &&  */}
        <div className={`${styles.chatMessage__files}`}>
          {message.attachments_info?.map((el: any, kk: number) => {
            return <AttachmentItemChat el={el} key={kk} />
          })}
        </div>
        {/* } */}
        <div className={`${styles.chatMessage__time}`}>{moment(message.created_at).format("HH:mm")}</div>
      </div>
    </div>
  )
}

export default ChatMessage
