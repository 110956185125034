export default function HistoryLayoutLoader() {
  return (
    <div>
      <div className={"skeletonBlock"} style={{ height: "30px", borderRadius: "8px", marginBottom: "31px" }} />
      <div className={"skeletonBlock"} style={{ height: "30px", borderRadius: "8px", marginBottom: "24px" }} />
      <div
        className={`selectBack`}
        style={{ width: "208px", height: "32px", borderRadius: "4px", marginBottom: "8px" }}
      />
      <div className={`selectBack`} style={{ height: "92px", borderRadius: "16px", marginBottom: "8px" }} />
      <div className={`selectBack`} style={{ height: "92px", borderRadius: "16px", marginBottom: "8px" }} />
    </div>
  )
}
