import Layout from "../components/Layout/Layout"
import OrdersLayout from "../components/OrdersLayout/OrdersLayout"
import HistoryLayoutLoader from "../components/LayoutLoaders/HistoryLayoutLoader"

export default function Orders() {
  return (
    <Layout mainLoader={<HistoryLayoutLoader />} isHeaderMobHidden>
      <OrdersLayout layout={"active"} />
    </Layout>
  )
}
