import ArticlePrevItem from "../ArticlePrevItem/ArticlePrevItem"
import { useTranslation } from "react-i18next"
import { IArticle } from "../../../types/content"
import styles from "./ArticlesSearch.module.scss"

interface Props {
  isOnlyTag?: boolean
  clearSearch: () => void
  list: IArticle[]
}

const ArticlesSearch = ({ isOnlyTag, clearSearch, list }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  return (
    <div className={styles.block}>
      <header className={styles.header}>
        <h4 className={styles.title}>{t(isOnlyTag ? "bySelectedTags" : "searchResult")}</h4>
        <button className={styles.btn} type={"button"} onClick={clearSearch}>
          {t(isOnlyTag ? "clearTags" : "clear")}
        </button>
      </header>

      <div>
        {list.length > 0 ? (
          list.map((item) => {
            return <ArticlePrevItem key={item.id} {...item} isTags className={styles.item} />
          })
        ) : (
          <p className={styles.noFound}>{t("searchServiceNotFound")}</p>
        )}
      </div>
    </div>
  )
}

export default ArticlesSearch
