import { FC, useEffect, useState } from "react"
import TitleBack from "../TitleBack/TitleBack"
import { useTranslation } from "react-i18next"
import { useLazyGetOrdersWithoutReviewsQuery, useLazyGetReviewsQuery } from "../../redux/api/review"
import clsx from "clsx"
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll"
import { IOrderWithoutReview, IReview } from "../../types/content"
import Reviews from "../Reviews/Reviews"
import styles from "./ReviewsLayout.module.scss"

const LIMIT_REVIEWS = 10

const ReviewsLayout: FC = () => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const [getOrdersWR, { isFetching: isFetchingOrder, isUninitialized }] = useLazyGetOrdersWithoutReviewsQuery()
  const [getReviews, { isFetching: isFetchingReview }] = useLazyGetReviewsQuery()

  const [isOrdersEnd, setOrdersEnd] = useState<boolean>(false)
  const [offsetOrder, setOffsetOrder] = useState(0)
  const [offset, setOffset] = useState(0)
  const [isListEnd, setListEnd] = useState<boolean>(false)
  const [reviewsState, setReviewsState] = useState<{
    orders: IOrderWithoutReview[]
    reviews: IReview[]
  }>({
    orders: [],
    reviews: [],
  })

  useEffect(() => {
    // первая загрузка заявок без отзывов
    getOrdersWR({ limit: LIMIT_REVIEWS })
      .unwrap()
      .then((res) => {
        setReviewsState((prev) => {
          return { ...prev, orders: res?.orders || [] }
        })
        setOrdersEnd(res.bIsEnd)
        setOffsetOrder((prev) => prev + LIMIT_REVIEWS)
      })
  }, [])

  useEffect(() => {
    // первая загрузка отзывов, если заявки без отзывов закончились
    if (!isOrdersEnd) return
    getReviews({ limit: LIMIT_REVIEWS })
      .unwrap()
      .then((res) => {
        setReviewsState((prev) => {
          return { ...prev, reviews: res?.reviews || [] }
        })
        if (res.bIsEnd) setListEnd(true)
        setOffset((prev) => prev + LIMIT_REVIEWS)
      })
  }, [isOrdersEnd])

  const fetchData = async () => {
    if (isOrdersEnd) {
      getReviews({ limit: LIMIT_REVIEWS, offset: offset })
        .unwrap()
        .then((res) => {
          setReviewsState((prev) => {
            return { ...prev, reviews: [...prev.reviews, ...res.reviews] }
          })
          setOffset((prev) => prev + LIMIT_REVIEWS)
          setListEnd(res.bIsEnd)
        })
    } else {
      getOrdersWR({ limit: LIMIT_REVIEWS, offset: offsetOrder })
        .unwrap()
        .then((res) => {
          setReviewsState((prev) => {
            return { ...prev, orders: [...prev.orders, ...res.orders] }
          })
          setOffsetOrder((prev) => prev + LIMIT_REVIEWS)
          setOrdersEnd(res.bIsEnd)
        })
    }
  }

  const infiniteScroll = useInfiniteScroll<any>(
    async () => {
      if (isListEnd || isFetchingReview || isFetchingOrder) return
      await new Promise((resolve) => {
        setTimeout(resolve, 1000)
        void fetchData()
      })
    },
    { distance: 200, isDocument: true },
  )

  return (
    <>
      <TitleBack title={t("myReviewsTitle")} className={styles.titleBack} />
      <div>
        {!isUninitialized && (
          <>
            <Reviews reviews={reviewsState.orders} isOrder setReviews={setReviewsState} />
            <Reviews reviews={reviewsState.reviews} />
            {!reviewsState?.orders && !reviewsState?.reviews && !isFetchingReview && !isFetchingOrder && (
              <div className={styles.noReviews}>{t("noReviewsYet")}</div>
            )}
          </>
        )}
        {(infiniteScroll.isLoading || isFetchingReview || isFetchingOrder) && !isListEnd && (
          <>
            <div className={clsx("skeletonBlock", styles.subtitleLoader)} />
            <div className={clsx("skeletonBlock", styles.itemLoader)} />
            <div className={clsx("skeletonBlock", styles.itemLoader)} />
          </>
        )}
      </div>
    </>
  )
}

export default ReviewsLayout
