import { Dispatch, FC, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import NoFindModalContent from "./NoFindModalContent"
import styles from "./NoFindModal.module.scss"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

const NoFindModal: FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  return (
    <ModalPortal
      isOpen={open}
      setIsOpen={setOpen}
      className={styles.modal}
      header={<h2 className={"modal__title"}>{t("sendReq")}</h2>}
    >
      <NoFindModalContent setOpen={setOpen} />
    </ModalPortal>
  )
}

export default NoFindModal
