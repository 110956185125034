export default function ProductsLayoutLoader() {
  return (
    <div>
      <div className={"skeletonBlock"} style={{ height: "30px", borderRadius: "8px", marginBottom: "31px" }} />
      <div className={"skeletonBlock"} style={{ height: "30px", borderRadius: "8px", marginBottom: "62px" }} />
      <div className={`selectBack`} style={{ height: "92px", borderRadius: "16px", marginBottom: "8px" }} />
      <div className={`selectBack`} style={{ height: "92px", borderRadius: "16px", marginBottom: "8px" }} />
    </div>
  )
}
