export default function ArticlesLayoutLoader() {
  return (
    <div>
      <div className={"skeletonBlock"} style={{ height: "30px", borderRadius: "8px", marginBottom: "24px" }} />
      <div style={{ display: "flex", gridGap: "8px", marginBottom: "24px" }}>
        {[...Array(4)].map((_item, index) => (
          <div
            key={index}
            className={"skeletonBlock"}
            style={{ width: "60px", height: "32px", borderRadius: "47px" }}
          />
        ))}
      </div>
      <div className={"skeletonBlock"} style={{ height: "150px", borderRadius: "12px" }} />
    </div>
  )
}
