import TextareaAutosize from "react-textarea-autosize"
import Button from "../../Assets/Button/Button"
import { useTranslation } from "react-i18next"
import { useFormikContext } from "formik"
import { Dispatch, SetStateAction, useEffect } from "react"
import styles from "./NoFindModal.module.scss"

const NoFindModalContent = ({ setOpen }: { setOpen: Dispatch<SetStateAction<boolean>> }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const { values, setFieldValue, submitForm, isSubmitting } = useFormikContext<{ [key: string]: any }>()

  const openNextModal = () => {
    if (values.text && values.text.trim().length > 0) {
      void submitForm()
    }
  }

  useEffect(() => {
    return void setFieldValue("text", "")
  }, [])

  return (
    <div className={`application-for-new-service`}>
      <p>{t("discribeReq")}</p>
      <div className={`modal-input modal-input_cancel`}>
        <div className={`input-label`}>{t("comment")}</div>
        <TextareaAutosize
          minRows={1}
          maxRows={3}
          placeholder={t("enterComment")}
          onChange={(e) => setFieldValue("text", e.target.value)}
          value={values.text}
        />
      </div>
      <div className={styles.footer}>
        <Button txt={t("back")} mode={"gray"} size={"sm-width"} onClick={() => setOpen(false)} />
        <Button
          txt={t("send")}
          className={styles.btnSubmit}
          onClick={openNextModal}
          disabled={!values.text.length}
          isLoading={isSubmitting}
        />
      </div>
    </div>
  )
}

export default NoFindModalContent
