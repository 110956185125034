import Layout from "../components/Layout/Layout"
import ReviewsLayout from "../components/ReviewsLayout/ReviewsLayout"
import ReviewLayoutLoader from "../components/LayoutLoaders/ReviewLayoutLoader"

export default function ReviewsPage() {
  return (
    <Layout mainLoader={<ReviewLayoutLoader />} isHeaderMobHidden>
      <ReviewsLayout />
    </Layout>
  )
}
