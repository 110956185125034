import clsx from "clsx"
import styles from "./MessagesModalLoader.module.scss"

const MessagesModalLoader = () => {
  return (
    <div className={clsx("messages-loader", styles.loader)}>
      <div className={styles.mesLoader}>
        {[...Array(4)].map((_i, index) => (
          <div key={index} className={clsx(styles.listLoader, index >= 2 && styles["listLoader--reverse"])}>
            <div className={clsx("skeletonBlock", styles.listLoader__avatar)} />
            <div className={clsx("skeletonBlock", styles.listLoader__txt)} />
          </div>
        ))}
      </div>
      <div className={clsx("skeletonBlock", styles.footerLoader)} />
    </div>
  )
}

export default MessagesModalLoader
