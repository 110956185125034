export default function ProductLayoutLoader() {
  return (
    <div>
      <div
        className={`selectBack`}
        style={{ width: "170px", height: "28px", borderRadius: "42px", marginBottom: "16px" }}
      />
      <div className={`selectBack`} style={{ height: "400px", borderRadius: "12px" }} />
    </div>
  )
}
