import { Dispatch, FC, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import Button from "../../Assets/Button/Button"
import styles from "./SuccessModal.module.scss"
import clsx from "clsx"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  modalTitle?: string
  modalText?: string
}

const SuccessModal: FC<Props> = ({ open, setOpen, modalTitle, modalText }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  return (
    <ModalPortal isOpen={open} setIsOpen={setOpen} className={styles.modal}>
      {modalTitle && <h2 className={clsx("modal__title")}>{modalTitle}</h2>}
      {modalText && <p className={styles.txt}>{modalText}</p>}
      <div className={styles.footer}>
        <Button txt={t("thank")} onClick={() => setOpen(false)} />
      </div>
    </ModalPortal>
  )
}

export default SuccessModal
