// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SuccessModal_modal__gffKC {
  align-items: flex-end;
  padding: 12px;
}
@media (min-width: 640px) {
  .SuccessModal_modal__gffKC {
    align-items: center;
    padding: 66px 40px 40px;
  }
}
.SuccessModal_modal__gffKC .modal__inner {
  max-width: 360px;
}
.SuccessModal_modal__gffKC .modal__close-btn {
  top: 12px;
  right: 16px;
}
.SuccessModal_modal__gffKC .modal__title {
  padding-right: 30px;
  margin-bottom: 16px;
}

.SuccessModal_txt__0tunU {
  margin-bottom: 24px;
}

.SuccessModal_footer__RYhM9 {
  display: flex;
  justify-content: center;
}
@media (min-width: 1200px) {
  .SuccessModal_footer__RYhM9 {
    justify-content: flex-end;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/SuccessModal/SuccessModal.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,aAAA;AACF;AACE;EAJF;IAKI,mBAAA;IACA,uBAAA;EAEF;AACF;AAEM;EACE,gBAAA;AAAR;AAGM;EACE,SAAA;EACA,WAAA;AADR;AAIM;EACE,mBAAA;EACA,mBAAA;AAFR;;AAQA;EACE,mBAAA;AALF;;AAQA;EACE,aAAA;EACA,uBAAA;AALF;AAOE;EAJF;IAKI,yBAAA;EAJF;AACF","sourcesContent":[".modal {\r\n  align-items: flex-end;\r\n  padding: 12px;\r\n\r\n  @media (min-width: 640px) {\r\n    align-items: center;\r\n    padding: 66px 40px 40px;\r\n  }\r\n\r\n  :global {\r\n    .modal {\r\n      &__inner {\r\n        max-width: 360px;\r\n      }\r\n\r\n      &__close-btn {\r\n        top: 12px;\r\n        right: 16px;\r\n      }\r\n\r\n      &__title {\r\n        padding-right: 30px;\r\n        margin-bottom: 16px;\r\n      }\r\n    }\r\n  }\r\n}\r\n\r\n.txt {\r\n  margin-bottom: 24px;\r\n}\r\n\r\n.footer {\r\n  display: flex;\r\n  justify-content: center;\r\n\r\n  @media (min-width: 1200px) {\r\n    justify-content: flex-end;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `SuccessModal_modal__gffKC`,
	"txt": `SuccessModal_txt__0tunU`,
	"footer": `SuccessModal_footer__RYhM9`
};
export default ___CSS_LOADER_EXPORT___;
