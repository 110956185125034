import { FC } from "react"
import styles from "./OrdersList.module.scss"

interface Props {
  isMainLoad?: boolean
}

const OrdersSkeleton: FC<Props> = ({ isMainLoad }) => {
  return (
    <div className={styles.ordersList}>
      {isMainLoad && (
        <>
          <div className={`selectBack ${styles.ordersList__headSk}`} />
          <div className={`selectBack ${styles.ordersList__itemSk}`} />
        </>
      )}
      <div className={`selectBack ${styles.ordersList__itemSk}`} />
      <div className={`selectBack ${styles.ordersList__itemSk}`} />
      <div className={`selectBack ${styles.ordersList__itemSk}`} />
    </div>
  )
}

export default OrdersSkeleton
