export default function ReviewLayoutLoader() {
  return (
    <div>
      <div className={"skeletonBlock"} style={{ height: "30px", borderRadius: "8px", marginBottom: "24px" }} />
      <div
        className={`selectBack`}
        style={{ width: "208px", height: "18px", borderRadius: "4px", marginBottom: "12px" }}
      />
      <div className={`selectBack`} style={{ height: "219px", borderRadius: "12px", marginBottom: "12px" }} />
      <div className={`selectBack`} style={{ height: "219px", borderRadius: "12px", marginBottom: "12px" }} />
    </div>
  )
}
