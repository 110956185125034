// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Reviews_mainTitle__14J1n {
  margin-bottom: 31px;
}

.Reviews_block__P0cem {
  margin-bottom: 31px;
}

.Reviews_title__efZrh {
  font-weight: 600;
  margin-bottom: 12px;
}

.Reviews_item__nNx\\+o {
  margin-bottom: 12px;
}
.Reviews_item__nNx\\+o:last-child {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Reviews/Reviews.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF;AACE;EACE,gBAAA;AACJ","sourcesContent":[".mainTitle {\r\n  margin-bottom: 31px;\r\n}\r\n\r\n.block {\r\n  margin-bottom: 31px;\r\n}\r\n\r\n.title {\r\n  font-weight: 600;\r\n  margin-bottom: 12px;\r\n}\r\n\r\n.item {\r\n  margin-bottom: 12px;\r\n\r\n  &:last-child {\r\n    margin-bottom: 0;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainTitle": `Reviews_mainTitle__14J1n`,
	"block": `Reviews_block__P0cem`,
	"title": `Reviews_title__efZrh`,
	"item": `Reviews_item__nNx+o`
};
export default ___CSS_LOADER_EXPORT___;
