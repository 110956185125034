import { useEffect, useState } from "react"
import { useGetProductInfoQuery, useGetCategoriesQuery } from "../../redux/api/main"
import LocalizedLink from "../../hoc/LocalizedLink"
import { ArrowLeftBoldIcon } from "../../icons/ArrowLeftBoldIcon"
import { toBase64 } from "../../utils/helpers"
import { Form, Formik } from "formik"
import OrderServiceForm from "./OrderServiceForm"
import { addActiveOrdersCount } from "../../redux/slice/auth"
import { authModalOpen } from "../../redux/slice/authModal"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { usePostCreateOrderMutation } from "../../redux/api/content"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import clsx from "clsx"
import orderServiceStyles from "./OrderService.module.scss"
import styles from "./OrderService.module.scss"

interface IOption {
  alias?: string
  conditions?: any[]
  id?: string
  name?: string
  type?: string
}

const OrderService = ({ id, categoryId }: { id: string; categoryId: string }) => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const params = useParams()
  const navigate = useNavigate()

  const isObjectPage = pathname.includes("object")

  const { token } = useAppSelector((state) => state.auth)

  const { data, isLoading: isLoadingProduct } = useGetProductInfoQuery(id)
  const { data: categories, isLoading: isLoadingList } = useGetCategoriesQuery()
  const [createOrder] = usePostCreateOrderMutation()

  const [validateIds, setValidateIds] = useState<any[]>([])
  const [isLoadingSubmit, setLoadingSubmit] = useState<boolean>(false)
  // Страница конкретного объекта
  // const isObjectPage = pathname.includes("object")
  const [initialValuesForm, setInitialValuesForm] = useState<{ [key: string]: any }>({})

  const newInitVal: { [key: string]: any } = {}
  const createInitialValues = (options: any) => {
    options &&
      options.forEach((option: any, i: number) => {
        if (option.type === "text") newInitVal[option.id] = ""
        if (option.type === "boolean") newInitVal[option.id] = null
        if (option.type === "checkbox") newInitVal[option.id] = null
        if (option.type === "number") newInitVal[option.id] = null
        if (option.type === "datetime") newInitVal[option.id] = undefined
        if (option.type === "list") newInitVal[option.id] = []
        if (option.type === "photo") newInitVal[option.id] = []
        if (option.type === "attachment") newInitVal[option.id] = []
        if (option.type === "information") newInitVal[option.id] = ["information", 0]

        if (option.conditions.length > 0) {
          option.conditions.forEach((condition: any) => createInitialValues(condition.options))
        }
      })

    newInitVal.service_id = data?.id
    newInitVal.count = 1

    const isHaveTechObj = data?.technicalObjects
    if (isHaveTechObj) {
      newInitVal["technical_object_id"] = isObjectPage ? params?.id : undefined
    }
    // if (isHaveTechObj && !isObjectPage) newInitVal["technical_object_id"] = undefined
    // if (isHaveTechObj && isObjectPage) newInitVal["technical_object_id"] = params?.id

    return newInitVal
  }

  useEffect(() => {
    // if (data?.technicalObjects?.length > 0) setIsHaveTechObj(true)
    setInitialValuesForm(createInitialValues(data?.options))
  }, [data])

  const deepFindNeedOption = (parentArr: IOption[], searchedId: string): IOption | null => {
    const needEl = parentArr.find((el) => el.id === searchedId)
    let isFind = false
    if (needEl) return needEl

    let result: IOption | null = null
    parentArr.forEach((ell) => {
      if (ell?.conditions?.length) {
        ell?.conditions?.forEach((elll: any) => {
          if (!isFind) {
            result = deepFindNeedOption(elll.options, searchedId)
            if (result) isFind = true
          }
        })
      }
    })
    return result
  }
  const validateOpt = (values: any) => {
    const errors: any = {}
    const boolTypes = ["boolean"]
    const arrTypes = ["list", "attachment", "photo"]

    validateIds.forEach((el: any) => {
      if (boolTypes.includes(el.type)) {
        if (values?.[el.id] === null) errors[el.id] = "empty"
      } else if (arrTypes.includes(el.type) && !values?.[el.id]?.length) {
        errors[el.id] = "empty"
      } else if (!values?.[el.id]) {
        errors[el.id] = "empty"
      }
      // if (values?.[el.id]?.length === 0 || !values?.[el.id]) errors[el.id] = "empty"
    })
    if (data?.technicalObjects && data.technicalObjects.length > 0 && !values?.technical_object_id) {
      errors.technical_object_id = "selectObj"
    }

    return errors
  }

  const submitForm = async (values: { [key: string]: any }, actions: any) => {
    if (!data?.service_action_name) return
    const formData = new FormData()
    let key: keyof typeof values
    let arrNum = 0

    for (key in values) {
      if (key !== "count" && key !== "service_id" && key !== "technical_object_id" && key !== "technical_object_name") {
        if (Array.isArray(values[key]) && values[key].length && values[key][0] !== "information") {
          let numArr = 0
          for (const item of values[key]) {
            formData.append(`options[${arrNum}][id]`, key as string)

            const fileName = item?.name ?? ""
            if (item instanceof Blob && data?.options) {
              const attachmentType = deepFindNeedOption(data.options, key)?.type
              const file = await toBase64(item)
              //@ts-ignore
              formData.append(
                `options[${arrNum}][${attachmentType === "attachment" ? "files" : "images"}][${numArr}]${
                  attachmentType === "attachment" ? "[data]" : ""
                }`,
                file as any,
              )
              if (attachmentType === "attachment") {
                formData.append(
                  `options[${arrNum}][${attachmentType === "attachment" ? "files" : "images"}][${numArr}][filename]`,
                  fileName,
                )
              }
            } else {
              formData.append(`options[${arrNum}][items][${numArr}]`, item)
            }
            numArr++
          }
          arrNum++
        } else if (Array.isArray(values[key]) && values[key][0] && values[key][0] === "information") {
          formData.append(`options[${arrNum}][id]`, key)
          formData.append(`options[${arrNum}][wasShowed]`, values[key][1])
          arrNum++
        } else if (typeof values[key] === "boolean" || (!Array.isArray(values[key]) && values[key])) {
          formData.append(`options[${arrNum}][id]`, key)
          formData.append(`options[${arrNum}][value]`, values[key])
          arrNum++
        }
      }
    }

    formData.append("service_id", values.service_id)
    formData.append("count", `${values.count}`)
    // if (data?.technicalObjects?.length === 1 && data?.technicalObjects?.[0]?.children_count) {
    //   formData.append("technical_object_id", `${data?.technicalObjects[0]?.id}`)
    // }
    if (values.technical_object_id) {
      formData.append("technical_object_id", `${values.technical_object_id}`)
    }
    // else if (isObjectPage) {
    //   formData.append("technical_object_id", `${data?.id}`)
    // }
    // formData.forEach((val, key) => {
    //   console.log(key + ":" + val)
    // })
    // return

    const userToken = token || localStorage.getItem("token")
    if (userToken) {
      actions.setSubmitting(true)
      setLoadingSubmit(true)
      try {
        await createOrder(formData)
          .unwrap()
          .then((res) => {
            actions.resetForm()
            dispatch(addActiveOrdersCount())
            actions.setSubmitting(false)
            setLoadingSubmit(false)
            navigate(`/order/${res.data.id}`)
          })
      } catch (e) {
        console.warn(e)
        actions.setSubmitting(false)
        setLoadingSubmit(false)
      }
    } else {
      dispatch(authModalOpen({ flag: true }))
    }
  }

  return (
    <>
      {isLoadingProduct || isLoadingList ? (
        <div className={clsx("skeletonBlock", orderServiceStyles.linkLoader)} />
      ) : (
        <LocalizedLink to={`/products/${categoryId}`} className={styles.link}>
          <ArrowLeftBoldIcon />
          {categories?.find((category) => category.id === categoryId)?.name}
        </LocalizedLink>
      )}

      <Formik
        enableReinitialize
        initialValues={initialValuesForm}
        onSubmit={(values, actions) => {
          void submitForm(values, actions)
        }}
        validate={validateOpt}
      >
        {() => (
          <Form>
            {isLoadingProduct ? (
              <div className={clsx("skeletonBlock", styles.bodyLoading)} />
            ) : (
              data && <OrderServiceForm data={data} isLoadingSubmit={isLoadingSubmit} setValidateIds={setValidateIds} />
            )}
          </Form>
        )}
      </Formik>
    </>
  )
}

export default OrderService
