export default function EmployeesLayoutLoader() {
  return (
    <div>
      <div className={"skeletonBlock"} style={{ height: "30px", borderRadius: "8px", marginBottom: "24px" }} />
      <div className={"skeletonBlock"} style={{ height: "30px", borderRadius: "8px", marginBottom: "24px" }} />
      <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: "12px" }}>
        <div className={"skeletonBlock"} style={{ height: "164px", borderRadius: "12px" }} />
        <div className={"skeletonBlock"} style={{ height: "164px", borderRadius: "12px" }} />
        <div className={"skeletonBlock"} style={{ height: "164px", borderRadius: "12px" }} />
        <div className={"skeletonBlock"} style={{ height: "164px", borderRadius: "12px" }} />
      </div>
    </div>
  )
}
