import Avatar from "../../Assets/Avatar/Avatar"
import { getApiUrl } from "../../../utils/getApiUrl"
import moment from "moment/moment"
import LocalizedLink from "../../../hoc/LocalizedLink"
import Label from "../../Assets/Label/Label"
import clsx from "clsx"
import { IArticle } from "../../../types/content"
import styles from "./ArticlePrevItem.module.scss"

interface Props extends IArticle {
  isTags?: boolean
  className?: string
}

const ArticlePrevItem = ({ text, name, created_at, id, creator, tags, isTags, className }: Props) => {
  const currentLang = localStorage.getItem("i18nextLng") || "en"

  return (
    <div className={clsx(styles.item, className)}>
      {creator && (
        <header className={styles.itemHeader}>
          <Avatar
            img={creator.avatar_id ? getApiUrl() + "/web/user/avatar/" + creator.avatar_id : ""}
            name={creator.name}
            size={"xs"}
          />
          <div className={styles.info}>
            {creator.name}
            <p className={styles.date}>
              <span />
              {moment(created_at).locale(currentLang).format("DD MMMM")}
            </p>
          </div>
        </header>
      )}

      <LocalizedLink to={`/articles/${id}`} className={styles.txt}>
        {name || text}
      </LocalizedLink>

      {isTags && tags && tags.length > 0 && (
        <div className={styles.tags}>
          {tags.map((item, index) => (
            <Label key={index} txt={item.name} mode={"gray"} />
          ))}
        </div>
      )}
    </div>
  )
}

export default ArticlePrevItem
