// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Label_label__4pT77 {
  display: inline-flex;
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--gray-100);
  border-radius: 8px;
  min-height: 24px;
  color: #667799;
  padding: 3px 8px 3px 6px;
}
.Label_label--with-icon__2dxjo {
  padding-left: 3px;
}
.Label_label--orange__hPEGi {
  background-color: rgba(246, 230, 218, 0.42);
  color: #E79559;
  border: none;
}
@media (min-width: 1200px) {
  .Label_label--orange__hPEGi {
    border-radius: 4px;
  }
}
.Label_label--gray__ZXMr3 {
  background-color: var(--grey-25);
  color: #7A808B;
  font-size: var(--fz-sm);
  line-height: var(--lh-sm);
  border: none;
}
@media (min-width: 1200px) {
  .Label_label--gray__ZXMr3 {
    border-radius: 4px;
  }
}
.Label_label--sm__Z0hGI {
  font-size: var(--fz-sm);
  line-height: var(--lh-sm);
  font-weight: 500;
  letter-spacing: -0.02em;
  min-height: 18px;
  padding: 2px 4px;
}

.Label_icon__5IVOE {
  color: var(--accent-color);
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/Assets/Label/Label.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,mBAAA;EACA,8BAAA;EACA,iCAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;EAEA,wBAAA;AAAF;AAEE;EACE,iBAAA;AAAJ;AAGE;EACE,2CAAA;EACA,cAAA;EACA,YAAA;AADJ;AAGI;EALF;IAMI,kBAAA;EAAJ;AACF;AAGE;EACE,gCAAA;EACA,cAAA;EACA,uBAAA;EACA,yBAAA;EACA,YAAA;AADJ;AAGI;EAPF;IAQI,kBAAA;EAAJ;AACF;AAGE;EACE,uBAAA;EACA,yBAAA;EACA,gBAAA;EACA,uBAAA;EACA,gBAAA;EACA,gBAAA;AADJ;;AAKA;EACE,0BAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;AAFF","sourcesContent":[".label {\r\n  display: inline-flex;\r\n  align-items: center;\r\n  background-color: var(--white);\r\n  border: 1px solid var(--gray-100);\r\n  border-radius: 8px;\r\n  min-height: 24px;\r\n  color: #667799;\r\n  // flex-shrink: 0;\r\n  padding: 3px 8px 3px 6px;\r\n\r\n  &--with-icon {\r\n    padding-left: 3px;\r\n  }\r\n\r\n  &--orange {\r\n    background-color: rgba(246, 230, 218, .42);\r\n    color: #E79559;\r\n    border: none;\r\n\r\n    @media (min-width: 1200px) {\r\n      border-radius: 4px;\r\n    }\r\n  }\r\n\r\n  &--gray {\r\n    background-color: var(--grey-25);\r\n    color: #7A808B;\r\n    font-size: var(--fz-sm);\r\n    line-height: var(--lh-sm);\r\n    border: none;\r\n\r\n    @media (min-width: 1200px) {\r\n      border-radius: 4px;\r\n    }\r\n  }\r\n\r\n  &--sm {\r\n    font-size: var(--fz-sm);\r\n    line-height: var(--lh-sm);\r\n    font-weight: 500;\r\n    letter-spacing: -0.02em;\r\n    min-height: 18px;\r\n    padding: 2px 4px;\r\n  }\r\n}\r\n\r\n.icon {\r\n  color: var(--accent-color);\r\n  width: 16px;\r\n  height: 16px;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  margin-right: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `Label_label__4pT77`,
	"label--with-icon": `Label_label--with-icon__2dxjo`,
	"label--orange": `Label_label--orange__hPEGi`,
	"label--gray": `Label_label--gray__ZXMr3`,
	"label--sm": `Label_label--sm__Z0hGI`,
	"icon": `Label_icon__5IVOE`
};
export default ___CSS_LOADER_EXPORT___;
