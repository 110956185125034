import { useCheckTokenMutation } from "../redux/api/auth"
import { Navigate, useLocation } from "react-router-dom"
import { useEffect } from "react"

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation()
  const [checkToken, { status, data, isUninitialized }] = useCheckTokenMutation({
    fixedCacheKey: "authCacheKey",
  })

  //TODO: проверить, может стоит вернуть
  // if ((status === "fulfilled" && !data?.data?.user?.accessToken) || (status === "uninitialized" && !isUninitialized)) {
  // return <Navigate to="/" state={{ from: location }} replace />
  // }

  return children
}

export default RequireAuth
