import Layout from "../../components/Layout/Layout"
import RoomLayout from "../../components/RoomLayout/RoomLayout"
import { Outlet } from "react-router-dom"
import MainLayoutLoader from "../../components/LayoutLoaders/MainLayoutLoader"

export default function ObjectPageWrap() {
  // TODO: class room-main in globals.scss remove?
  //   <Layout isHeaderMobHidden mainLoader={<MainLayoutLoader />}>
  return (
    <Layout mainClass={"room-main"} isHeaderMobHidden>
      {/*<Outlet />*/}
      <RoomLayout>
        <Outlet />
      </RoomLayout>
    </Layout>
  )
}
