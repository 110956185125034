import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IArticle } from "../../types/content"

interface ICompanyLogo {
  type: string
  data: string
}

type ParamsState = {
  isSearchVal: boolean
  isOrderWithoutRevCheck: boolean
  articlesAsideList: IExArticle[]
  companyLogo: ICompanyLogo
}
interface IExArticle extends IArticle {
  children: IExArticle[]
}

const initialState: ParamsState = {
  isSearchVal: false,
  isOrderWithoutRevCheck: false,
  articlesAsideList: [],
  companyLogo: {
    type: "",
    data: "",
  },
}

export const paramsSlice = createSlice({
  name: "params",
  initialState,
  reducers: {
    setIsSearchVal: (state: ParamsState, action: PayloadAction<boolean>) => {
      state.isSearchVal = action.payload
    },
    setOrderWithoutRevCheck: (state: ParamsState, action: PayloadAction<boolean>) => {
      state.isOrderWithoutRevCheck = action.payload
    },
    setArticlesAsideList: (state: ParamsState, action: PayloadAction<IExArticle[]>) => {
      state.articlesAsideList = action.payload
    },
    setCompanyLogo: (state: ParamsState, action: PayloadAction<ICompanyLogo>) => {
      state.companyLogo = action.payload
    },
  },
})

export const { setIsSearchVal, setArticlesAsideList, setCompanyLogo } = paramsSlice.actions

export const selectParams = (state: any): ParamsState => state[paramsSlice.name]
