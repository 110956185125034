import Layout from "../../../components/Layout/Layout"
import ProductsLayout from "../../../components/ProductsLayout/ProductsLayout"
import ProductsLayoutLoader from "../../../components/LayoutLoaders/ProductsLayoutLoader"

export default function Products() {
  return (
    <Layout mainLoader={<ProductsLayoutLoader />} isHeaderMobHidden>
      <ProductsLayout />
    </Layout>
  )
}
