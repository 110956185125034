import { FC, useMemo, useState } from "react"
import clsx from "clsx"
import { IEmployee } from "../../../types/content"
import { getApiUrl } from "../../../utils/getApiUrl"
import Avatar from "../../Assets/Avatar/Avatar"
import { ChatIcon } from "../../../icons/ChatIcon"
import MessagesModal from "../../Modals/MessagesModal/MessagesModal"
import { useCreateChatMutation } from "../../../redux/api/chat"
import BtnLoader from "../../Assets/BtnLoader/BtnLoader"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { selectAuth } from "../../../redux/slice/auth"
import styles from "./Employee.module.scss"
import { setMessagesModalFirstChat, setMessagesModalIsOpen } from "../../../redux/slice/modals"
import { useTranslation } from "react-i18next"

interface Props extends IEmployee {
  className?: string
  highlightTxt?: string
  size?: "sm"
  onClick?: (val: string, name: string, userID: string) => void
}

const colors = ["#FA8B81", "#FED45A", "#2DBE64"]

const Employee: FC<Props> = ({
  className,
  size,
  onClick,
  highlightTxt,
  id,
  name,
  avatar_id,
  position,
  email,
  phone_number,
  add_phone_number,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const { user } = useAppSelector(selectAuth)
  const [createChat, { isLoading: isLoadingCreateChat }] = useCreateChatMutation()
  const dispatch = useAppDispatch()

  const [chatId, setChatId] = useState<string>("")

  const img = useMemo(() => {
    if (avatar_id) {
      return (
        <Avatar className={styles.avatar} img={getApiUrl() + "/web/user/avatar/" + avatar_id} name={name} size={"lg"} />
      )
    }
    if (!name) return null
    const userName = name.split(" ")
    const avatarNameFirst = userName[0].split("")[0]
    const avatarNameSecond = userName.length > 1 ? userName[1].split("")[0] : ""
    const color = colors[Math.floor(Math.random() * colors.length)]
    return (
      <div className={clsx(styles.imgDummy)} style={{ backgroundColor: color }}>
        {avatarNameFirst}
        {avatarNameSecond}
      </div>
    )
  }, [avatar_id, name])

  const userName = useMemo(() => {
    if (!highlightTxt) return name
    const currentSearch = highlightTxt.toLowerCase()
    const index = name.toLowerCase().indexOf(currentSearch)
    let searchedName = name
    if (index >= 0) {
      searchedName =
        searchedName.substring(0, index) +
        `<span class=${styles.highlight}>` +
        searchedName.substring(index, index + currentSearch.length) +
        "</span>" +
        searchedName.substring(index + currentSearch.length)
      return searchedName
    } else {
      return name
    }
  }, [name, highlightTxt])

  const handleOpenChat = () => {
    createChat({ type: "users", id: id })
      .unwrap()
      .then((res) => {
        dispatch(setMessagesModalFirstChat(res))
        dispatch(setMessagesModalIsOpen(true))
      })
  }

  return (
    <>
      <div
        className={clsx(styles.item, size && styles["item--" + size], className)}
        onClick={() => {
          if (onClick) onClick(id, name, id)
        }}
      >
        <div className={styles.content}>
          <p className={styles.name} dangerouslySetInnerHTML={{ __html: userName }} />

          {size !== "sm" && (
            <>
              {position && <p className={styles.position}>{position}</p>}
              {email && (
                <a href={"mailto:" + email} className={styles.link}>
                  {email}
                </a>
              )}
              <div className={styles.phoneWrap}>
                {phone_number && (
                  <a href={"tel:" + phone_number} className={styles.link}>
                    {phone_number}
                  </a>
                )}
                {phone_number && add_phone_number && ", "}
                {add_phone_number && (
                  <a href={"tel:" + add_phone_number} className={styles.link}>
                    {add_phone_number}
                  </a>
                )}
              </div>
              {id && user?.id !== id && (
                <button
                  disabled={isLoadingCreateChat}
                  type={"button"}
                  className={clsx(styles.btn, isLoadingCreateChat && styles["btn--is-loading"])}
                  onClick={handleOpenChat}
                >
                  {isLoadingCreateChat && <BtnLoader />}
                  <ChatIcon />
                  {t("write")}
                </button>
              )}
            </>
          )}
        </div>

        {img}
      </div>
    </>
  )
}

export default Employee
