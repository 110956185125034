import { getApiUrl } from "./getApiUrl"
import userBgColors from "./userBgColor"

interface Props {
  name: any
  avatar: any
  color?: any
}

export const GetUserAvatar = ({ name, avatar, color }: Props) => {
  if (!name) return null
  const localAbr = `${name[0]}${name.split(" ").length > 1 ? name.split(" ")[1][0] : name[1]}`

  const numbAmount = localAbr[0].charCodeAt(0) - 1071 + localAbr[1].charCodeAt(0) - 1071
  const userBgColor = userBgColors[Math.abs(numbAmount) % userBgColors.length]

  if (avatar) {
    return (
      <div className="select-abr">
        <img src={`${getApiUrl()}/web/user/avatar/${avatar}`} alt="avatar" />
      </div>
    )
  } else {
    return (
      <div className="select-abr" style={{ backgroundColor: color || userBgColor }}>
        {localAbr}
      </div>
    )
  }
}
